    <template>
        <v-row justify="center">
            <v-col cols="12">
                <v-data-table :loading="isLoading('get:equipamentos')" :items="filteredEquipamentos" :search="search" :headers="headers" class="fixed-header-table" fixed-header height="700px" :items-per-page="-1">
                    <template v-slot:top>
                        <v-row class="mt-2">
                            <v-col cols="5">
                                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable/>
                            </v-col>
                            <v-col class="text-end">
                                <v-badge left bordered overlap :content="activeFiltersCount">
                                    <v-btn class="mr-2 elevation-0" text @click="showFilters = !showFilters">
                                        <v-icon v-if="!showFilters" left>mdi-filter</v-icon>
                                        <v-icon v-else left>mdi-filter-off</v-icon>
                                        Filtros
                                    </v-btn>
                                </v-badge>
                                <!-- <v-btn class="mr-4 elevation-0" text @click="collapseAll">
                                    Recolher Linhas
                                </v-btn> -->
                                <!-- <v-btn class="mr-4 elevation-0" text @click="atualizar">
                                    <v-icon left>mdi-reload</v-icon>
                                    Atualizar
                                </v-btn> -->
                                <v-btn color="success" class="elevation-0" @click="openModal">
                                    <v-icon left>mdi-plus</v-icon>
                                    Novo
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-expand-transition>
                            <v-card outlined v-show="showFilters" dense>
                                <h4 class="ml-4 mt-4">Filtrar por</h4>
                                <v-row dense class="py-2 px-4">
                                    <v-col :cols="2">
                                        <v-select hide-details :items="statusOptions" label="Status" item-text="label" item-value="value"
                                        v-model="filters.is_active.value" outlined dense></v-select>
                                    </v-col>
                                    <v-col :cols="4">
                                        <v-select hide-details :items="fornecedores" label="Fornecedor"  item-text="nome_fantasia" item-value="id"
                                        v-model="filters.fornecedor.value" outlined dense></v-select>
                                    </v-col>
                                    <v-col :cols="2">
                                        <v-select hide-details :items="aquisicao" label="Nota Fiscal"  item-text="nota_fiscal" item-value="id"
                                         v-model="filters.nota_fiscal.value" outlined dense></v-select>
                                    </v-col>
                                </v-row>
                                <v-card-actions>
                                    <v-row dense>
                                        <v-col class="text-end">
                                            <v-btn color="error" text elevation="0" @click="clearFilters">
                                            Limpar filtros
                                            </v-btn>
                                            <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">
                                            Aplicar filtros
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-expand-transition>
                    </template>
                    <template v-slot:[`item.edit`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                                    <v-icon color="green">mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Clique para editar</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.patrimonio`]="{ item }">
                        <v-chip :color="'red'" v-if="!item.patrimonio">
                            <span>N/A</span>
                        </v-chip>
                        <span v-else>{{ item.patrimonio }}"</span>
                    </template>
                    <template v-slot:[`item.descricao`]="{ item }">
                        <v-chip :color="'red'" v-if="!item.nome">
                            <span>N/A</span>
                        </v-chip>
                        <span v-else>{{ item.patrimonio }}"</span>
                    </template>
                    <template v-slot:[`item.fornecedor`]="{ item }">
                        <v-chip :color="'red'" v-if="!item.aquisicao || !item.aquisicao.fornecedor">
                            <span>N/A</span>
                        </v-chip>
                        <span v-else>{{ item.aquisicao.fornecedor.nome_fantasia }}</span>
                    </template>
                    <template v-slot:[`item.razaoSocial`]="{ item }">
                        <v-chip :color="'red'" v-if="!item.aquisicao.fornecedor.razaoSocial">
                            <span>N/A</span>
                        </v-chip>
                        <span v-else>{{ item.razaoSocial }}</span>
                    </template>
                    <template v-slot:[`item.data`]="{ item }">
                        {{  item.data }}
                    </template>
                    <template v-slot:[`item.dataAquisicao`]="{ item }">
                        {{ item.dataAquisicao }}
                    </template>
                    <template v-slot:[`item.dataValidadeGarantia`]="{ item }">
                        {{ item.dataValidadeGarantia}}
                    </template>
                    <template v-slot:[`item.ativo`]="{ item }">
                        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                            <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
                        </v-chip>
                    </template>
                </v-data-table>
                <v-dialog v-model="dialog.create">
                    <v-card>
                        <v-card-title class="sticky-title title-border">
                            Controle:
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closeModal">
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-tabs v-model="tabIndex">
                            <v-tab>Dados de Equipamento</v-tab>
                            <v-tab-item>
                                <CadastroControle :currentItem="newItem" :onSubmit="createEquipament" :loading="isLoading('post:equipamentos')"  v-if="tabIndex === 1"/>
                            </v-tab-item>

                        </v-tabs>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="1000">
                    <v-card>
                        <v-card-title class="blod_color">
                            Editar Equipamento Id: {{ selectedRow.id }}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog.update = false">
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <EditControleEquipamento :currentItem="selectedRow" :loading="isLoading('put:equipamento')" :onSubmit="updateEquipament"></EditControleEquipamento>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </template>

    <script>
    import api from '../../../http';
    import UtilsFunc from "../../../service/utilsFunc";
    import CadastroControle from './CadastroControle.vue';
    import { mapMutations } from 'vuex'
    import EditControleEquipamento from './EditControleEquipamento.vue'

    const { withCRUDUtils } = UtilsFunc
    const { fetchData } = UtilsFunc

    export default {
        name: 'Controle',
        components: {
            CadastroControle,
            EditControleEquipamento
        },
        data: () => withCRUDUtils ({
            modal: false,
            filtersApplied: false,
            equipamentos: [],   
            fornecedores: [],
            aquisicao: [],
            loading: false,
            search: '',
            singleExpand: false,
            showFilters: false,
            filteredData: [],
            statusOptions: [
                { label: "Ativo", value: true },
                { label: "Inativo", value: false },
            ],
            tabIndex: 0,
            newItem: {
                ativo: null
            },
            newItemAquisicao: {
                ativo: null
            },
            filters: {
                is_active: { value: null, compareType: 'equal', prop: 'ativo' },
                fornecedor: { value: null, compareType: 'equal', prop: 'aquisicao.fornecedor.id' },
                nota_fiscal: { value: null, compareType: 'equal', prop: 'aquisicao.id' }
            },
            activeFilters: [],
            headers: [
                { text: "Editar", value: "edit", width: 100 },
                { text: "Patrimônio", align: "center", sortable: true, value: "patrimonio" },
                {text: "Descrição", align: "center", sortable: true, value: "descricao.nome" },
                { text: "Fornecedor", align: "start", sortable: true, value: "aquisicao.fornecedor.nome_fantasia" },
                { text: "Razão Social", align: "center", sortable: true, value: "aquisicao.fornecedor.razao_social" },
                { text: "Data", align: "start", sortable: true, value: "" },
                { text: "Aquisição", align: "start", sortable: true, value: "aquisicao.dataAquisicao" },
            ],
        }),
        computed: {
            // ...mapState({
            //     selectedEquipament: (state) => state.selectedEquipament
            // }),
            activeFiltersCount() {
                return Object.values(this.filters).filter(Boolean).length
            },
            filteredEquipamentos() {
                if (this.filtersApplied) {
                    const filterBy = UtilsFunc.controlFilter(this.activeFilters); 
                    return this.tableData.filter(filterBy);
                }
                return this.tableData;
            }

        },  
        
        methods: {
            ...mapMutations(['setModal']),
            openModal() {
                this.dialog.create = true
                this.modal = true
                this.setModal(this.modal)
            },
            closeModal() {
                this.dialog.create = false
                this.modal = false
                this.setModal(this.modal)
            },
            applyFilters() {
                this.filtersApplied = true;  
                
                this.activeFilters = {
                    is_active: this.filters.is_active.value,
                    fornecedor: this.filters.fornecedor.value,
                    nota_fiscal: this.filters.nota_fiscal.value,
                };

                this.filteredData = this.tableData.filter(UtilsFunc.controlFilter(this.activeFilters));
            },

            clearFilters() {
                this.activeFilters = []
                this.filters.is_active.value = null
                this.filters.fornecedor.value = null
                this.filters.nota_fiscal.value = null
            },
           
            async createEquipament() {
                const LOADING_NAME = "post:equipamentos"
                this.setLoading(LOADING_NAME)
                try {
                    const fields = {
                        ...this.newItem
                    }
                    console.log("Dados do item enviado => ", fields)
                    const response = await api.post('equipamentos/equipamentos/', fields)
                    console.log(response.data)
                    this.$toast.success('Equipamento Criado com Sucesso')
                    await this.getEquipamentos()
                } catch (error) {
                    this.$errorHandler(error);
                    this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
                } finally {
                    this.setLoading(LOADING_NAME, true);
                }
            },

            async updateEquipament(fields) {
                const LOADING_NAME = 'put:equipamento'
                this.setLoading(LOADING_NAME)
                try {
                    await api.put(`/equipamentos/equipamento/${fields.id}/`, fields)
                    this.dialog.update = false
                    this.$toast.success('Equipamento atualizado com sucesso!')
                }
                catch (error) {
                    this.$errorHandler(error)
                    this.$toast.error(`Desculpe! Aconteceu Algo errado. Deu error ${error}`)
                }
                finally {
                    this.setLoading(LOADING_NAME, true)
                }
            },

            async getEquipamentos() {
                await fetchData('equipamentos/equipamentos/', 'get:equipamentos', this, 'tableData')},

            async getAquisicoes() {
                await fetchData('equipamentos/aquisicao/', 'get:aquisicao', this, 'aquisicao')},
            
            async getFornecedores() {
                await fetchData('fornecedores/fornecedores/', 'get:fornecedor', this, 'fornecedores')},

        },
        mounted() {
            this.getEquipamentos(),
            this.getFornecedores(),
            this.getAquisicoes()
        }
    }
    </script>

    <style scoped>
    .grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
    </style>