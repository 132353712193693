<template>
    <div class="pa-4">
        <v-form @submit.prevent="localOnSubmit" ref="formRef">
            <div>
                <v-legend>Dados de Equipamentos</v-legend>
            </div>
            <div class="grid-container">
                <v-select dense :items="nomeequipamentos" item-text="nome" item-value="id" return-object v-model="currentItem.descricao" name="descricao" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Descrição <span style="color: red">*</span></span>
                    </template>
                </v-select>
                <v-select :items="depositos" item-text="nome" item-value="id" return-object name="cod.almox" v-model="currentItem.deposito" dense :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Cód.Almox.<span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <v-text-field dense name="patrimonio" v-model="currentItem.patrimonio" :rules="patrimonioRules" outlined>
                    <template v-slot:label>
                        <span>Patrimônio<span style="color: red">*</span></span>
                    </template>
                </v-text-field>
                <v-text-field dense name="fabricante" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Fabricante<span style="color: red">*</span></span>
                    </template>
                </v-text-field>
                <v-text-field dense name="aparelho" label="Aparelho" outlined></v-text-field>  
                <v-select name="tipo" :items="tipo" item-text="label" item-value="value" dense  v-model="currentItem.propriedade" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Tipo<span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <v-text-field dense name="valor" v-model="currentItem.preco" :rules="valorRules" v-money="{precision: 2, decimal: ',', thousands: '.'}" :key="priceKey" outlined>
                    <template v-slot:label>
                        <span>Valor do Equipamento<span style="color: red">*</span></span>
                    </template>
                </v-text-field>
                <v-select name="tipo" :items="aquisicao" item-text="nota_fiscal" item-value="id" return-object dense  v-model="currentItem.aquisicao" @change="getFornecedor" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Nota Fiscal<span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <v-text-field dense name="fornecedor" label="Fornecedor" v-model="fornecedor" :readonly="true" outlined></v-text-field>
            </div>
            <v-row dense>
                <v-col>
                    <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
                        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
                        <v-btn :color="currentItem.ativo  ? 'green' : ''">Ativo</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row class="justify-end dense">
                <v-col class="text-end">
                    <v-tooltip top :disabled="isFormValid">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                            </span>
                        </template>
                        <span>Preencha todos os campos obrigatórios (*) para habilitar o botão</span>
                    </v-tooltip>  
                </v-col>
        </v-row>
        </v-form>
        <v-data-table v-if="equipamentosCadastrados.length" :items="equipamentosCadastrados" :headers="headers" class="mt-4" dense>
            <template v-slot:[`item.descricao`]="{ item }">
                <span>{{ item.descricao.nome }}</span>    
            </template>
            <template v-slot:[`item.deposito`]="{ item }">
                <span>{{ item.deposito.nome }}</span>    
            </template>
            <template v-slot:[`item.patrimonio`]="{ item }">
                <span>{{ item.patrimonio }}</span>    
            </template>
            <template v-slot:[`item.propriedade`]="{ item }">
                <span v-if="item.propriedade">Equipamento próprio</span>  
                <span v-else>Equipamento Alugado</span>     
            </template>
            <template v-slot:[`item.ativo`]="{ item }">
                <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                    <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
                </v-chip>
            </template>
            <template v-slot:[`item.preco`]="{ item }">
                <span>{{ item.preco }}</span>    
            </template>
        </v-data-table>
    </div>
</template>

<script>
import api from '@/http'
import { mapState } from 'vuex';
import UtilsFunc from '../../../service/utilsFunc'
import ConfirmButton from '../../ConfirmButton.vue'


const { withCRUDUtils } = UtilsFunc

export default {
    name: "CadastroControle",
    data: () => withCRUDUtils ({
        priceKey: 0,
        aquisicao: [],
        depositos: [],
        fornecedor: '',
        nota_fiscal: '',
        nomeequipamentos: [],
        equipamentos: [],
        equipamentosCadastrados: [],
        headers: [ 
            { text: 'Descrição', value: 'descricao.nome' },
            { text: 'Depósito', value: 'deposito.nome' },
            { text: 'Patrimônio', value: 'patrimonio' },
            { text: 'Fabricante', value: 'fabricante' },
            { text: 'Tipo', value: 'propriedade' },
            { text: 'Status', value: 'ativo' },
            { text: 'Valor do Equipamento', value: 'preco' },
        ],
        tipo: [
            {label: 'Próprio', value: true},
            {label: 'Alugado', value: false}
        ],
        status: [
            {label: 'Ativo', value: 0},
            {label: 'Inativo', value: 1},
            {label: 'Em manutenção', value: 2}
        ],
        requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório']
    }),
    components: { ConfirmButton },
    props: {
        onSubmit: Function,
        loading: Boolean,
        formRef: Object,
        currentItem: { type: Object, 
            default: () => ({
                ativo: null,
            })
        },
    },
    computed: {
        ...mapState({
            modal: (state) => state.modal
        }),

        isFormValid() {
            return !!(this.currentItem.descricao &&
                this.currentItem.deposito &&
                this.currentItem.patrimonio &&
                this.currentItem.propriedade &&
                this.currentItem.preco &&
                this.currentItem.aquisicao 
            )
        },
        patrimonioRules() {
            return [
                p => !!p || 'Obrigatório',
                p => this.numberValidate(p) || 'Insira apenas números'
            ]
        },
        valorRules() {
            return [
                v => !!v || 'Obrigatório',
                v => this.currencyValidate(v) || 'Insira um valor real'
            ]
        }
    },
    watch: {
        'modal': function(newModalState) {
        if (!newModalState) {
            // O modal foi fechado, execute o que for necessário
            this.reset();  // Chama a função reset que você já tem
        }
    }
    },
    methods: {
        reset() {
            if(!this.modal) {
                this.$refs.formRef.reset()
                this.equipamentosCadastrados = []
            }
        },
        formatPrice(price) {
            if(typeof price !== 'string') {
                return price
            }
            return parseFloat(price.replace(/\./g, '').replace(',', '.'))
        },
        numberValidate(value) {
            if(value == '') {
                return false
            }
            const regex = /^\d+$/
            return regex.test(value)
        },
        currencyValidate(value) {
            if(value == '') {
                return false
            }
            const regex = /^\d{1,3}(\.\d{3})*,\d{2}$/
            return regex.test(value)
        },
        setValorAquisicao(aquisicao) {
            if (!aquisicao || typeof aquisicao !== 'object') {
                console.warn("Aquisicao inválida ou não fornecida.");
                return;
            }
            aquisicao.valor = 0
            console.log('Equipamentos: ', this.equipamentos);
            const equipamentosRelacionados = this.equipamentos.filter(equipamento => {
                console.log('Nota fiscal do equipamento:', equipamento.aquisicao?.nota_fiscal);
                console.log('Nota fiscal selecionada:', this.nota_fiscal);
                return equipamento.aquisicao?.nota_fiscal != null && equipamento.aquisicao.nota_fiscal === this.nota_fiscal;
            });
            console.log('Equipamentos relacionados:', equipamentosRelacionados);
            
            const somaValores = equipamentosRelacionados.reduce((total, equipamento) => {
                const preco = parseFloat(equipamento.preco?.toString().replace(/\./g, '').replace(',', '.')) || 0;
                return total + preco
            }, 0)

            const precoAtual = parseFloat(this.currentItem.preco?.toString().replace(/\./g, '').replace(',', '.')) || 0;

            aquisicao.valor += somaValores + precoAtual

            return aquisicao

        },
        async getAquisicoes() {
            const LOADING_NAME = 'get:aquisicao'
            this.setLoading(LOADING_NAME)
            const { data } = await api.get('equipamentos/aquisicao/')
            this.setLoading(LOADING_NAME, true)
            return this.aquisicao = data
        },
        getFornecedor(aquisicao) {
            if(aquisicao) {
                this.fornecedor = aquisicao.fornecedor.nome_fantasia
                this.nota_fiscal = aquisicao.nota_fiscal
            } else {
                this.fornecedor = ''
                this.nota_fiscal = ''
            }
        },
        async localOnSubmit() {
            this.isSubmitting = true
            let aquisicao = this.aquisicao.find(aquisicao => aquisicao.nota_fiscal === this.nota_fiscal)
            console.log('Aquisicao: ', aquisicao)
            const id = aquisicao.id

            this.currentItem.preco = this.formatPrice(this.currentItem.preco)
            try {
                this.equipamentosCadastrados.push({ ...this.currentItem })
                console.log()
                this.onSubmit({
                    ...this.currentItem,
                    ativo: Boolean(this.currentItem.ativo),
                }).finally(() => {
                    this.isSubmitting = false
                })
                await this.getEquipamentos()
                this.updateAquisicao(aquisicao, id)
                this.currentItem.preco = ""
                this.priceKey += 1 
                this.$nextTick(() => {
                    this.$refs.formRef.reset()
                })
            } catch (error) {
                console.error('Ocorreu um erro: ', error)
            }
        },
        async getNomeEquipamentos() {
            const LOADING_NAME = "get:nomeequipamentos";
            this.setLoading(LOADING_NAME);
            this.loading = true;
            try {
                const response = await api.get('equipamentos/nomeequipamentos/');
                this.nomeequipamentos = response.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.loading = false;
            }
        },

        async getDepositos() {
            const LOADING_NAME = "get:depositos";
            this.setLoading(LOADING_NAME);
            this.loading = true;
            try {
                const response = await api.get('uteis/deposito/');
                this.depositos = response.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.loading = false;
            }
        },

        async getEquipamentos() {
            const LOADING_NAME = "get:equipamentos";
            this.setLoading(LOADING_NAME);
            this.loading = true;
            try {
                const response = await api.get('equipamentos/equipamentos/');
                this.equipamentos = response.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.loading = false;
            }
        },

        async updateAquisicao(aquisicao, id) {
            const LOADING_NAME = 'put:aquisicao'
            let newAquisicao = this.setValorAquisicao(aquisicao)
            console.log('Nova Aquisicao: ', newAquisicao)
            this.setLoading(LOADING_NAME)
            try {
                await api.put(`equipamentos/aquisicao/${id}/`, newAquisicao)
            } catch (error) {
                this.$errorHandler(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado: ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true)
            }
        }
    },
    mounted() {
        this.getAquisicoes(),
        this.getNomeEquipamentos(),
        this.getEquipamentos(),
        this.getDepositos()
    },
    onUnmounted() {
        this.reset()
    }
}
</script>

<style scoped>

div.grid-container {
display: flex;
flex-wrap: wrap;
gap: 0.5rem;
}

.flex-item-nome {
flex: 1 1 200px; 
min-width: 400px; 
}

</style>