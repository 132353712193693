<template>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
        <div class="grid-container">
                <v-select dense :items="nomeequipamentos" item-text="nome" item-value="id" return-object v-model="currentItem.descricao" name="descricao" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Descrição <span style="color: red">*</span></span>
                    </template>
                </v-select>
                <v-select :items="depositos" item-text="nome" item-value="id" return-object name="cod.almox" v-model="currentItem.deposito" dense :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Cód.Almox.<span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <v-text-field dense name="patrimonio" v-model="currentItem.patrimonio" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Patrimônio<span style="color: red">*</span></span>
                    </template>
                </v-text-field>
                <v-text-field dense name="fabricante" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Fabricante<span style="color: red">*</span></span>
                    </template>
                </v-text-field>
                <v-text-field dense name="aparelho" label="Aparelho" outlined></v-text-field>  
                <v-select name="tipo" :items="tipo" item-text="label" item-value="value" dense  v-model="currentItem.propriedade" :rules="requiredField" outlined>
                    <template v-slot:label>
                        <span>Tipo<span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <vuetify-money dense name="valor" label="Valor do Equipamento" v-model="currentItem.preco" outlined></vuetify-money>
                <v-select name="tipo" :items="aquisicao" item-text="nota_fiscal" item-value="id" return-object dense  v-model="currentItem.aquisicao" :rules="requiredField" @change="setFornecedor" outlined>
                    <template v-slot:label>
                        <span>Nota Fiscal<span style="color: red;">*</span></span>
                    </template>
                </v-select>
                <v-text-field dense name="fornecedor" label="Fornecedor" v-model="fornecedor" :readonly="true" outlined></v-text-field>
            </div>
            <v-row dense>
                <v-col>
                    <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
                        <v-btn :color="!currentItem.ativo ? 'error' : ''" :value="false">Inativo</v-btn>
                        <v-btn :color="currentItem.ativo  ? 'green' : ''" :value="true">Ativo</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row class="justify-end dense">
                <v-col class="text-end">
                    <v-tooltip top :disabled="isFormValid">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                            </span>
                        </template>
                        <span>Preencha todos os campos obrigatórios (*) para habilitar o botão</span>
                    </v-tooltip>  
                </v-col>
        </v-row>
    </v-form>
</template>

<script>
import api from '@/http'
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

const { withCRUDUtils } = UtilsFunc
const { fetchData } = UtilsFunc
export default { 
    name: 'EditControleEquipamento',
    props: {
        currentItem: Object,
        onSubmit: Function,
        loading: Boolean
    },
    components: {
        ConfirmButton
    },
    data: () => withCRUDUtils ({
        nomeequipamentos: [],
        depositos: [],
        aquisicao: [],
        equipamentos: [],
        isSubmitting: false,
        tipo: [
            {label: 'Próprio', value: true},
            {label: 'Alugado', value: false}
        ],
        status: [
            {label: 'Ativo', value: 0},
            {label: 'Inativo', value: 1},
            {label: 'Em manutenção', value: 2}
        ],
        fornecedor: '',
        nota_fiscal: '',
        requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório']
    }),
    computed: {
        isFormValid() {
            return !!(this.currentItem.descricao &&
                this.currentItem.deposito &&
                this.currentItem.patrimonio &&
                this.currentItem.propriedade &&
                this.currentItem.preco &&
                this.currentItem.aquisicao 
            )
        },
    },
    methods: {
        calculateSumValues(equipamentos, currentItem) {
            return equipamentos.reduce((total, equipamento) => {
                if (equipamento.id === currentItem.id) return total;

                const preco = parseFloat(
                    equipamento.preco?.toString().replace(/\./g, '').replace(',', '.')
                ) || 0;

                return total + preco;
            }, 0);
        },

        setValorAquisicao(aquisicao) {
            if (!aquisicao) {
                console.warn("Aquisicao inválida ou não fornecida.");
                return;
            }

            const equipamentosRelacionados = this.equipamentos.filter(equipamento =>
                equipamento.aquisicao?.nota_fiscal === this.nota_fiscal
            );

            const somaValores = this.calculateSumValues(equipamentosRelacionados, this.currentItem);

            aquisicao.valor = somaValores + (parseFloat(this.currentItem.preco) || 0);

            return aquisicao;
        },

        setFornecedor(aquisicao) {
            if(aquisicao) {
                this.fornecedor = aquisicao.fornecedor.nome_fantasia
                this.nota_fiscal = aquisicao.nota_fiscal
            } else {
                this.fornecedor = ''
                this.nota_fiscal = ''
            }
        },

        async updateAquisicao(aquisicao, id) {
            const LOADING_NAME = 'put:aquisicao'
            let newAquisicao = this.setValorAquisicao(aquisicao)
            this.setLoading(LOADING_NAME)
            try {
                await api.put(`equipamentos/aquisicao/${id}/`, newAquisicao)
            } catch (error) {
                this.$errorHandler(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado: ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true)
            }
        },

        async localOnSubmit() {
            this.isSubmitting = true
            let aquisicao = this.aquisicao.find(aquisicao => aquisicao.nota_fiscal === this.nota_fiscal)
            
            try {
                this.onSubmit({
                    ...this.currentItem,
                    ativo: Boolean(this.currentItem.ativo),
                }).finally(() => {
                    this.isSubmitting = false
                })
                await this.getEquipamentos()
                this.updateAquisicao(aquisicao, aquisicao.id)
            } catch (error) {
                console.error('Ocorreu um erro: ', error)
            }
        },

        async getEquipamentos() {
            await fetchData('equipamentos/equipamentos/', 'get:equipamentos', this, 'equipamentos')},

        async getNomeEquipamentos() {
            await fetchData('equipamentos/nomeequipamentos/', 'get:nomeequipamentos', this, 'nomeequipamentos') },

        async getDepositos() {
            await fetchData('uteis/deposito/', 'get:depositos', this, 'depositos')},

        async getAquisicoes() {
            await fetchData('equipamentos/aquisicao/', 'get:aquisicao', this, 'aquisicao')},
    },
    mounted() {
        this.getEquipamentos(),
        this.getNomeEquipamentos(),
        this.getAquisicoes(),
        this.getDepositos(),
        this.setFornecedor(this.currentItem.aquisicao),
        this.currentItem.ativo = Boolean(this.currentItem.ativo)
    }
}
</script>

<style scoped>

div.grid-container {
display: flex;
flex-wrap: wrap;
gap: 0.5rem;
}

</style>